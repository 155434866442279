import React from 'react';
import styled from 'styled-components';
import BulkSelectModalButton from '../../bulk_components/BulkSelectModalButton';
import Button from '../../components/Button';

function BulkProductPage(props) {
  return (
    <Wrapper>
      BulkProduct
      <BulkSelectModalButton />
      <Button>加入估價單</Button>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

export default BulkProductPage;
