import React from 'react';
import styled from 'styled-components';

function BulkSelectModalButton(props) {
  return <Wrapper>Button</Wrapper>;
}

const Wrapper = styled.div`
  padding: 10px 8px;
  border: 1px solid blue;
`;

export default BulkSelectModalButton;
